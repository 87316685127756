<template>
    <div class="main-content">
        <el-form inline size="small" label-width="100px">
            <el-form-item label="供应商ID：">
                <el-input class="input-width" v-model="filter.supplierId" placeholder="请输入供应商ID搜索"></el-input>
            </el-form-item>
            <el-form-item label="供应商名称：">
                <el-input class="input-width" v-model="filter.supplierName" placeholder="请输入供应商名称搜索"></el-input>
            </el-form-item>
            <el-form-item label="报表时间：">
                <el-select class="input-width" v-model="filter.routingMonth" placeholder="请选择月份">
                    <el-option label="全部" :value="null"></el-option>
                    <el-option v-for="item in monthList" :key="item" :label="item" :value="item"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" @click="search">查询</el-button>
                <button-permissions :datas="'download'" style="margin-left: 10px">
                    <el-button icon="el-icon-tickets" :loading="exportLoading" @click="exportDataFunc">导出</el-button>
                </button-permissions>
            </el-form-item>
        </el-form>

        <el-table class="table-back-write" :data="filter.list" v-loading="tableLoading" border>
            <el-table-column fixed="left" prop="supplierId" label="商户ID" width="100"></el-table-column>
            <el-table-column fixed="left" prop="supplierName" label="商户名称" min-width="180"></el-table-column>
            <el-table-column fixed="left" prop="routingMonth" label="月份" width="100"></el-table-column>
            <el-table-column prop="saleAmount" label="总销售额" width="140">
                <template slot="header" slot-scope="scope">
					<span>总销售额
						<el-tooltip class="item" effect="dark" content="统计所选月份内，已发货的订单总金额（按销售价计算）" placement="top-start">
							<i class="el-icon-question"></i>
						</el-tooltip>
					</span>
                </template>
            </el-table-column>
            <el-table-column prop="returnAmount" label="售后总金额" width="140">
                <template slot="header" slot-scope="scope">
					<span>售后总金额
						<el-tooltip class="item" effect="dark" content="统计所选月份内，售后成功的订单总金额（不考虑支付时间是否落在所选月份内）" placement="top-start">
							<i class="el-icon-question"></i>
						</el-tooltip>
					</span>
                </template>
            </el-table-column>
            <el-table-column prop="fianlAmount" label="有效销售额" width="140">
                <template slot="header" slot-scope="scope">
					<span>有效销售额
						<el-tooltip class="item" effect="dark" content="有效销售额=总销售额-售后总金额" placement="top-start">
							<i class="el-icon-question"></i>
						</el-tooltip>
					</span>
                </template>
            </el-table-column>
            <el-table-column prop="supplierAmount" label="应结算总成本" width="140"></el-table-column>
            <el-table-column prop="spreadAmount" label="推广服务费" width="120"></el-table-column>
            <el-table-column prop="storageAmount" label="仓储服务费" width="120"></el-table-column>
            <el-table-column prop="techniqueAmount" label="技术服务费" width="120"></el-table-column>
            <el-table-column prop="appreciationAmount" label="增值服务费" width="120"></el-table-column>
            <el-table-column fixed="right" label="操作" width="220">
                <template slot-scope="scope">
                    <el-button type="text" @click="showDetailFunc(scope, 1)">订单销售明细</el-button>
                    <el-button type="text" @click="showDetailFunc(scope, 2)">商品销售明细</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="text-align: right; padding: 15px" :current-page="filter.pageNo" :page-size="filter.pageSize"
                       :total="filter.total" :page-sizes="filter.sizes" @size-change="pageSizeChange" @current-change="currentChange"
                       background layout="total, sizes, prev, pager, next, jumper"></el-pagination>


        <el-dialog :title="orderListTitle" :visible.sync="orderListFlag" width="80%" top="30px" @closed="clearDataFunc">
            <OrderList v-if="orderListData" :formData="orderListData"></OrderList>
        </el-dialog>

        <el-dialog :title="saleListTitle" :visible.sync="saleListFlag" width="80%" top="30px" @closed="clearDataFunc">
            <SaleList v-if="saleListData" :formData="saleListData" :monthList="monthList"></SaleList>
        </el-dialog>
    </div>
</template>

<script>
    import {exportReportList, getQueryReportList, getQueryRoutingMonthList} from '@/api/finance';
    import OrderList from './orderList';
    import SaleList from './saleList';

    export default {
        name: 'reportForms',
        components: {
            OrderList,
            SaleList,
        },
        data() {
            return {
                tableLoading: false,
                searchTime: '',
                filter: {
                    supplierId: '',
                    supplierName: '',
                    routingMonth: '',
                    pageNo: 1,
                    pageSize: 10,
                    total: 0,
                    list: [],
                },
                monthList: [],
                orderListFlag: false,
                orderListData: null,
                orderListTitle: '供应商商品销量明细',

                saleListFlag: false,
                saleListData: null,
                saleListTitle: '销售订单明细',

                exportLoading: false,
            }
        },
        mounted() {
            this.getMonthList();
            this.search();
        },
        methods: {
            async getMonthList() {
                let { success, data } = await getQueryRoutingMonthList();
                if(success) {
                    this.monthList = data || [];
                }
            },
            search() {
                this.filter.pageNo = 1;
                this.getList();
            },
            pageSizeChange(size) {
                this.filter.pageSize = size;
                this.getList();
            },
            currentChange(page) {
                this.filter.pageNo = page;
                this.getList();
            },
            // 获取列表
            async getList() {
                this.tableLoading = true;
                let params = {
                    supplierId: this.filter.supplierId,
                    supplierName: this.filter.supplierName,
                    routingMonth: this.routingMonth,
                    pageNo: this.filter.pageNo,
                    pageSize: this.filter.pageSize,
                };
                let { success, data } = await getQueryReportList(params);
                if (success) {
                    this.filter.list = data.records || [];
                    this.filter.total = data.total;
                }
                setTimeout(() => {
                    this.tableLoading = false;
                }, 300);
            },
            // 关闭弹出层清除选中内容
            clearDataFunc() {
                this.orderListData = null;
                this.saleListData = null;
            },
            // 选择弹出层
            showDetailFunc({row}, type) {
                if(type === 1) {
                    this.orderListData = {...row};
                    this.orderListFlag = true;
                    this.orderListTitle = '订单销售明细';
                } else {
                    this.saleListData = {...row};
                    this.saleListFlag = true;
                    this.saleListTitle = '商品销售明细';
                }
            },
            //导出数据
            async exportDataFunc() {
                try {
                    let params = {
                        supplierId: this.filter.supplierId,
                        supplierName: this.filter.supplierName,
                        routingMonth: this.routingMonth,
                        backUserId: localStorage.getItem("mlmzpageUserId"),
                        backUserName: localStorage.getItem("mlmzpageRealName"),
                    };
                    this.exportLoading = true;
                    let { success } = await exportReportList(params);
                    if(success) {
                        this.$message({type: 'success', message: '导出成功，请到下载中心下载文件'});
                    }
                } catch (e) {
                    console.log(e);
                }
                this.exportLoading = false;
            },
        }
    }
</script>