<template>
    <div>
        <el-form inline size="small" label-width="100px">
            <el-form-item label="商品ID：">
                <el-input class="input-width" v-model="filter.goodsId" placeholder="请输入商品ID搜索"></el-input>
            </el-form-item>
            <el-form-item label="商品名称：">
                <el-input class="input-width" v-model="filter.goodsName" placeholder="请输入商品名称搜索"></el-input>
            </el-form-item>
            <el-form-item label="商品类型：">
                <el-select class="input-width" v-model="filter.goodsType" placeholder="请选择商品类型">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="A类" :value="1"></el-option>
                    <el-option label="B类" :value="2"></el-option>
                    <el-option label="C类" :value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选择月份：">
                <el-select class="input-width" v-model="filter.routingMonth" placeholder="请选择月份">
                    <el-option label="全部" :value="null"></el-option>
                    <el-option v-for="item in monthList" :key="item" :label="item" :value="item"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" @click="search">查询</el-button>
                <button-permissions :datas="'download'" style="margin-left: 10px">
                    <el-button icon="el-icon-tickets" :loading="exportLoading" @click="exportDataFunc">导出</el-button>
                </button-permissions>
            </el-form-item>
        </el-form>

        <el-table class="table-back-write" :data="filter.list" v-loading="tableLoading" border>
            <el-table-column prop="goodsId" label="商品ID" width="100"></el-table-column>
            <el-table-column prop="goodsName" label="商品名称" min-width="200"></el-table-column>
            <el-table-column prop="goodsType" label="商品分类" width="100">
                <template slot-scope="scope">
                    <span v-if="scope.row.goodsType === 1">A类</span>
                    <span v-if="scope.row.goodsType === 2">B类</span>
                    <span v-if="scope.row.goodsType === 3">C类</span>
                </template>
            </el-table-column>
            <el-table-column prop="brandName" label="品牌名称" width="120"></el-table-column>
            <el-table-column prop="routingMonth" label="月份" width="100"></el-table-column>
            <el-table-column prop="saleAmount" label="总销售额" width="100"></el-table-column>
            <el-table-column prop="returnAmount" label="售后商品金额" width="120"></el-table-column>
            <el-table-column prop="fianlAmount" label="有效销售额" width="100"></el-table-column>
            <el-table-column prop="supplierAmount" label="应结算成本" width="100"></el-table-column>
            <el-table-column prop="spreadAmount" label="推广服务费" width="100"></el-table-column>
            <el-table-column prop="storageAmount" label="仓储服务费" width="100"></el-table-column>
            <el-table-column prop="techniqueAmount" label="技术服务费" width="100"></el-table-column>
            <el-table-column prop="appreciationAmount" label="增值服务费" width="100"></el-table-column>
        </el-table>
        <el-pagination style="text-align: right; padding: 15px" :current-page="filter.pageNo" :page-size="filter.pageSize"
                       :total="filter.total" :page-sizes="filter.sizes" @size-change="pageSizeChange" @current-change="currentChange"
                       background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </div>
</template>

<script>
    import {exportGoodsReportList, getQueryGoodsReportList} from '@/api/finance';

    export default {
        props: {
            formData: {
                type: Object,
                default: null,
            },
            monthList: {
                type: Array,
                default: [],
            },
        },
        data() {
            return {
                tableLoading: false,
                filter: {
                    goodsId: '',
                    goodsName: '',
                    goodsType: '',
                    routingMonth: '',
                    pageNo: 1,
                    pageSize: 10,
                    total: 0,
                    list: [],
                },
                exportLoading: false,
            }
        },
        mounted() {
            console.log(this.formData);
            this.filter.routingMonth = this.formData.routingMonth;
            this.search();
        },
        methods: {
            search() {
                this.filter.pageNo = 1;
                this.getList();
            },
            pageSizeChange(size) {
                this.filter.pageSize = size;
                this.getList();
            },
            currentChange(page) {
                this.filter.pageNo = page;
                this.getList();
            },
            // 获取列表
            async getList() {
                this.tableLoading = true;
                let params = {
                    supplierId: this.formData.supplierId,
                    goodsId: this.filter.goodsId,
                    goodsName: this.filter.goodsName,
                    goodsType: this.filter.goodsType,
                    routingMonth: this.filter.routingMonth,
                    pageNo: this.filter.pageNo,
                    pageSize: this.filter.pageSize,
                };
                let { success, data } = await getQueryGoodsReportList(params);
                if (success) {
                    this.filter.list = data.records || [];
                    this.filter.total = data.total;
                }
                setTimeout(() => {
                    this.tableLoading = false;
                }, 300);
            },
            //导出数据
            async exportDataFunc() {
                try {
                    let params = {
                        supplierId: this.formData.supplierId,
                        goodsId: this.filter.goodsId,
                        goodsName: this.filter.goodsName,
                        goodsType: this.filter.goodsType,
                        routingMonth: this.filter.routingMonth,
                        backUserId: localStorage.getItem("mlmzpageUserId"),
                        backUserName: localStorage.getItem("mlmzpageRealName"),
                    };
                    this.exportLoading = true;
                    let { success } = await exportGoodsReportList(params);
                    if(success) {
                        this.$message({type: 'success', message: '导出成功，请到下载中心下载文件'});
                    }
                } catch (e) {
                    console.log(e);
                }
                this.exportLoading = false;
            },
        }
    }
</script>