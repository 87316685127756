<template>
    <div>
        <el-form inline size="small" label-width="100px">
            <el-form-item label="下单人ID：">
                <el-input class="input-width" v-model="filter.buyerUserId" placeholder="请输入售出人ID搜索"></el-input>
            </el-form-item>
            <el-form-item label="下单人昵称：">
                <el-input class="input-width" v-model="filter.nickName" placeholder="请输入售出人昵称搜索"></el-input>
            </el-form-item>
            <el-form-item label="手机号：">
                <el-input class="input-width" v-model="filter.phoneNumber" placeholder="请输入手机号搜索"></el-input>
            </el-form-item>
            <el-form-item label="订单编号：">
                <el-input class="input-width" v-model="filter.orderNo" placeholder="请输入订单编号搜索"></el-input>
            </el-form-item>
            <el-form-item label="商品名称：">
                <el-input class="input-width" v-model="filter.goodsName" placeholder="请输入商品名称搜索"></el-input>
            </el-form-item>
            <el-form-item label="商品类型：">
                <el-select class="input-width" v-model="filter.goodsType" placeholder="请选择商品类型">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="A类" :value="1"></el-option>
                    <el-option label="B类" :value="2"></el-option>
                    <el-option label="C类" :value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="用户等级：">
                <el-select class="input-width" v-model="filter.pusherLevel" placeholder="请选择用户等级">
                    <el-option label="全部" :value="null"></el-option>
                    <el-option v-for="item in LEVELLIST" :key="item.pusherLevel" :label="item.pusherLabel" :value="item.pusherLevel"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="订单状态：">
                <el-select class="input-width" v-model="filter.orderStatus" placeholder="请选择订单状态">
                    <el-option label="全部" value=""></el-option>
<!--                    <el-option label="待付款" :value="1"></el-option>-->
<!--                    <el-option label="待发货" :value="2"></el-option>-->
                    <el-option label="待收货" :value="3"></el-option>
                    <el-option label="待评价" :value="4"></el-option>
                    <el-option label="已完成" :value="5"></el-option>
                    <el-option label="已关闭" :value="6"></el-option>
                    <el-option label="退款中" :value="7"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="支付时间：">
                <el-date-picker class="input-width" v-model="createTimes" type="daterange" range-separator="～"
                                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" @click="search">查询</el-button>
                <button-permissions :datas="'download'" style="margin-left: 10px">
                    <el-button icon="el-icon-tickets" :loading="exportLoading" @click="exportDataFunc">导出</el-button>
                </button-permissions>
            </el-form-item>
        </el-form>

        <el-table class="table-back-write" :data="filter.list" v-loading="tableLoading" border>
            <el-table-column prop="buyerUserId" label="下单人ID" width="90"></el-table-column>
            <el-table-column prop="nickName" label="下单人昵称" width="100"></el-table-column>
            <el-table-column prop="phoneNumber" label="手机号" width="120"></el-table-column>
            <el-table-column prop="pusherLevel" label="等级" width="80">
                <template slot-scope="scope">
                    {{ scope.row.pusherLevel | getDistributRoleName }}
                </template>
            </el-table-column>
            <el-table-column prop="orderNo" label="订单编号" min-width="180"></el-table-column>
            <el-table-column prop="goodsName" label="产品名称" min-width="220"></el-table-column>
            <el-table-column prop="goodsType" label="商品类型" width="80">
                <template slot-scope="scope">
                    <span v-if="scope.row.goodsType === 1">A类</span>
                    <span v-if="scope.row.goodsType === 2">B类</span>
                    <span v-if="scope.row.goodsType === 3">C类</span>
                </template>
            </el-table-column>
            <el-table-column prop="brandName" label="品牌名称" width="120"></el-table-column>
            <el-table-column prop="originalSalePrice" label="订单金额" width="100"></el-table-column>
            <el-table-column prop="finalGoodsAmount" label="实付金额" width="100"></el-table-column>
            <el-table-column prop="profitDeductAmount" label="权益抵扣" width="100"></el-table-column>
            <el-table-column prop="couponReduceAmount" label="优惠券抵用" width="100"></el-table-column>
            <el-table-column prop="quantity" label="订单数量" width="90"></el-table-column>
            <el-table-column prop="orderStatus" label="订单状态" width="100">
                <template slot-scope="scope">
                    <div v-if="scope.row.afterSaleStatus < 0">
                        <el-tag v-if="scope.row.orderStatus === 1" type="warning" size="small" effect="dark">待付款</el-tag>
                        <el-tag v-if="scope.row.orderStatus === 2" type="primary" size="small" effect="dark">待发货</el-tag>
                        <el-tag v-if="scope.row.orderStatus === 3" type="primary" size="small" effect="dark">待收货</el-tag>
                        <el-tag v-if="scope.row.orderStatus === 4" type="success" size="small" effect="dark">待评价</el-tag>
                        <el-tag v-if="scope.row.orderStatus === 5" type="info" size="small" effect="dark">已完成</el-tag>
                        <el-tag v-if="scope.row.orderStatus === 6" type="info" size="small" effect="dark">已关闭</el-tag>
                        <el-tag v-if="scope.row.orderStatus === 7" type="danger" size="small" effect="dark">退款中</el-tag>
                    </div>
                    <div v-else>
                        <el-tag v-if="scope.row.afterSaleStatus === 0" type="warning" size="small" effect="dark">售后审核中</el-tag>
                        <el-tag v-if="scope.row.afterSaleStatus === 1" type="danger" size="small" effect="dark">售后成功</el-tag>
                        <el-tag v-if="scope.row.afterSaleStatus === 2" type="info" size="small" effect="dark">售后关闭</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="paymentTime" label="支付时间" width="160"></el-table-column>
        </el-table>
        <el-pagination style="text-align: right; padding: 15px" :current-page="filter.pageNo" :page-size="filter.pageSize"
                       :total="filter.total" :page-sizes="filter.sizes" @size-change="pageSizeChange" @current-change="currentChange"
                       background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </div>
</template>

<script>
    import {exportOrderDetailList, getQueryOrderDetailList} from '@/api/finance';

    export default {
        props: {
            formData: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                tableLoading: false,
                createTimes: '',
                filter: {
                    buyerUserId: '',
                    goodsName: '',
                    goodsType:'',
                    nickName: '',
                    orderNo: '',
                    orderStatus: '',
                    phoneNumber: '',
                    pusherLevel: '',
                    pageNo: 1,
                    pageSize: 10,
                    total: 0,
                    list: [],
                },
                exportLoading: false,
            }
        },
        mounted() {
            this.search();
        },
        methods: {
            search() {
                this.filter.pageNo = 1;
                this.getList();
            },
            pageSizeChange(size) {
                this.filter.pageSize = size;
                this.getList();
            },
            currentChange(page) {
                this.filter.pageNo = page;
                this.getList();
            },
            // 获取列表
            async getList() {
                this.tableLoading = true;
                let params = {
                    supplierId: this.formData.supplierId,
                    buyerUserId: this.filter.buyerUserId,
                    goodsName: this.filter.goodsName,
                    goodsType: this.filter.goodsType,
                    nickName: this.filter.nickName,
                    orderNo: this.filter.orderNo,
                    orderStatus: this.filter.orderStatus,
                    phoneNumber: this.filter.phoneNumber,
                    pusherLevel: this.filter.pusherLevel,
                    pageNo: this.filter.pageNo,
                    pageSize: this.filter.pageSize,
                };
                if(this.createTimes) {
                    params.payStartDate = this.createTimes[0] + ' 00:00:00';
                    params.payEndDate = this.createTimes[1] + ' 23:59:59';
                }
                let { success, data } = await getQueryOrderDetailList(params);
                if (success) {
                    this.filter.list = data.records || [];
                    this.filter.total = data.total;
                }
                setTimeout(() => {
                    this.tableLoading = false;
                }, 300);
            },
            //导出数据
            async exportDataFunc() {
                try {
                    let params = {
                        supplierId: this.formData.supplierId,
                        buyerUserId: this.filter.buyerUserId,
                        goodsName: this.filter.goodsName,
                        goodsType: this.filter.goodsType,
                        nickName: this.filter.nickName,
                        orderNo: this.filter.orderNo,
                        orderStatus: this.filter.orderStatus,
                        phoneNumber: this.filter.phoneNumber,
                        pusherLevel: this.filter.pusherLevel,
                        backUserId: localStorage.getItem("mlmzpageUserId"),
                        backUserName: localStorage.getItem("mlmzpageRealName"),
                    };
                    if(this.createTimes) {
                        params.payStartDate = this.dataRange[0];
                        params.payEndDate = this.dataRange[1];
                    }
                    this.exportLoading = true;
                    let { success } = await exportOrderDetailList(params);
                    if(success) {
                        this.$message({type: 'success', message: '导出成功，请到下载中心下载文件'});
                    }
                } catch (e) {
                    console.log(e);
                }
                this.exportLoading = false;
            },
        }
    }
</script>